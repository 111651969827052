import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import isLength from 'validator/lib/isLength';
import { useCookies } from 'react-cookie';
import { useToasts } from 'react-toast-notifications';
import { AltPayment, CartItem } from '@tovia/man-protos/dist/types/billing.types';
import { ensure } from '@tovia/man-app-utils/lib/helpers/type-utils';
import { noop } from 'src/client/helpers/noop';
import { TABLET_LANDSCAPE } from 'src/client/helpers/mediaQueries';
import { StoredOrderData } from 'src/shared/types/OrderResponse';
import { useURLSearchParams } from 'src/client/helpers/useURLSearchParams';
import { CreateOrderData } from 'src/shared/types/CreateOrderData';
import { useGoogleAnalyticsABTest } from 'src/client/helpers/useGoogleAnalytics';
import { ApiClient, SuperAgentError } from '../../helpers/ApiClient';
import { SubscriptionChoice } from './SubscriptionChoice';
import { PaymentChoice } from './PaymentChoice';
import { EmailForm } from './EmailForm';
import { PurchaseFrame } from '../../components/Purchase/PurchaseFrame';
import { ChargeByPrevious } from '../../components/Purchase/ChargeByPrevious';
import { apiUrls, cartStorageKey, routerPaths } from '../../helpers/constants';
import { Biller, BillerById } from '../../../shared/constants/billers';
import { delay, getData, saveData } from '../../helpers/utils';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { Header } from '../../components/TemplateA/Header/Header';
import { MainFooter } from '../../components/Footer/MainFooter';
import { ChatWidget } from '../../components/ChatWidget/ChatWidget';
import { SecuritySection } from '../../components/SecuritySection/SecuritySection';
import { RedirectSection } from '../../components/RedirectSection/RedirectSection';
import { LoginReminderModal } from '../../components/LoginReminderModal/LoginReminderModal';
import { Testimonials } from '../../components/Testimonials/Testimonials';
import { FeaturesList } from '../../components/FeaturesList/FeaturesList';
import UpgradeFrame from '../../components/Purchase/UpgradeFrame';
import { useAppData } from '../../helpers';
import { AlternativePayments } from './AlternativePayments';
import { JoinLayout } from './styles';
import { CascadeMessage } from './CascadeMessage';
import { usePreJoinPagePrices } from './usePreJoinPagePrices';
import { Teasers } from './Teasers';
import { useInfinityTagEvent } from '../../helpers/useInfinityTagEvents';
import { useFilterAlternativePayments } from './useFilterAlternativePayments';
import { useEmailOnBlur } from './useEmailOnBlur';
import { pushBeginCheckoutToGoogleAnalytics, pushSelectItemToGoogleAnalytics } from './googleAnalyticsEvents';
import { PreJoinPageABProps } from '../../helpers/ABSwitchComponent';
import { ConversionType, ConversionTypes } from '../../../shared/types/userMeasurement';

export const useQueryParams = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { site } = useAppData();
  const [cookies] = useCookies([`${site?.abbreviation}_PA`]);
  return useMemo(
    () => ({
      affiliateID: query.get('PA') || cookies[`${site?.abbreviation}_PA`],
      promoCode: query.get('promoCode'),
      giftCard: query.get('giftCard'),
      purchaseResponse: query.get('response'),
      biller: query.get('payment'),
      ipAddress: query.get('ipAddress'),
      galleryPurchase: query.get('pps'),
    }),
    [query, cookies, site],
  );
};

export const PreJoinPage = (props: PreJoinPageABProps) => {
  const { cdnUrl, site, user, emblemAgeVerificationRequired } = useAppData();
  const history = useHistory();
  const location = useLocation();
  const { affiliateID, promoCode, purchaseResponse, biller, galleryPurchase } = useQueryParams();
  const searchParams = useURLSearchParams();
  const { addToast } = useToasts();
  const [cookies] = useCookies(['mam', 'ata_SID', `${site?.abbreviation}_PA`]);
  const mamPartnerID = useMemo(() => (cookies.mam ? cookies.mam.split('.')[0] : null), [cookies.mam]);

  const purchaseMatch = !!useRouteMatch({ exact: true, path: routerPaths.purchase.site });
  const nonNudeMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.path });
  const nonNudePurchaseMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.purchase.site });

  // User, validation
  const isUser = user ? user.isUser : false;

  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');

  const [validEmail, setValidEmail] = useState<string | boolean>('');
  const [validPassword, setValidPassword] = useState<string | boolean>('');

  const [doesUserExist, setDoesUserExist] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Payment UI
  const [paymentOption, setPaymentOption] = useState<AltPayment | undefined>(undefined);
  const [selectedAltPayment, setSelectedAltPayment] = useState<AltPayment | undefined>(undefined);
  // selecting the alt payment option unselects the primary payment option, so
  // we just need a singular option for both of them
  const activePayment = paymentOption || selectedAltPayment;

  const pushGoogleAnalyticsEvent = useGoogleAnalyticsABTest('PreJoinPage');

  const [showAlternativePaymentPanel, setShowAlternativePaymentPanel] = useState(false);

  const [subOption, setSubOption] = useState<number | undefined>(undefined);
  const [iframeUrl, setIFrameUrl] = useState('');
  const [postURL, setPostURL] = useState('');
  const [postData, setPostData] = useState('');

  const [purchasing, setPurchasing] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [validationRequired, setValidationRequired] = useState(false);

  // Payment API
  const getPricesResponse = usePreJoinPagePrices(setPaymentOption);
  const paymentPrices = getPricesResponse?.paymentPrices;
  const countryName = getPricesResponse?.countryName;
  const previousBillerID = getPricesResponse?.previousBillerID || 0;
  const activeSub = getPricesResponse?.activeSub;
  const alternativePayments = getPricesResponse?.altPayments;

  const upgrade = !!activeSub;

  // Cascade
  const [isCascading, setIsCascading] = useState(false);
  const [cascadeItem, setCascadeItem] = useState<CartItem | undefined>(undefined);
  const [cascadeCountDown, setCascadeCountDown] = useState(0);

  // Charge By Previous (CBP)
  const [itemUUID, setItemUUID] = useState<string | undefined>(undefined);
  const [canCBP, setCanCBP] = useState(false);

  const sendSubmitInfoEventOnPurchase = useInfinityTagEvent({
    name: 'Submit info button (Payment info)',
    type: 'Revenue',
    value: '0.001',
  });
  const sendSelectPlanEventOnClick = useInfinityTagEvent({
    name: 'Select plan button (create membership)',
    type: 'Acquisition',
    value: '0.001',
  });

  const unselectActivePaymentByBillerId = (billerId: number) => {
    if (activePayment?.billerID === billerId) {
      selectAltPayment(undefined);
    }
  };
  const toggleUserExists = (userExists: boolean) => {
    setDoesUserExist(userExists);
    setShowLoginModal(userExists);
    setValidEmail(!userExists);
  };

  const { activeAlternativePayments, filterAlternativePaymentMethods } = useFilterAlternativePayments({
    alternativePayments,
    email,
    unselectActivePaymentByBillerId,
  });

  const { customError, emailOnBlur, isValidatingEmail } = useEmailOnBlur({
    email,
    emailOnBlurCallback: filterAlternativePaymentMethods,
    setValidEmail,
    toggleUserExists,
  });
  const GAEventABTestSentRef = useRef<boolean>(false);
  // AB test temporary measurement - valid input filled out
  useEffect(() => {
    const isValidForm = !!(!user.isUser && email && password && validEmail && validPassword && !isValidatingEmail);

    if (isValidForm && !GAEventABTestSentRef.current) {
      // send GA event on account info form filled out
      pushGoogleAnalyticsEvent('ab_test_account_info_filled_out', null);
      GAEventABTestSentRef.current = true;
    }
    props.signalFormEvent(isValidForm);
  }, [email, isValidatingEmail, password, props, user.isUser, validEmail, validPassword, pushGoogleAnalyticsEvent]);

  // AB test temporary measurement - initial sub attempt
  useEffect(() => {
    if (!purchasing) {
      return;
    }

    let conversionType: ConversionType = ConversionTypes.PreJoinAlternativePaymentSubmit;

    if (paymentOption?.name === 'PayPal') {
      conversionType = ConversionTypes.PreJoinPayPalSubmit;
    } else if (paymentOption?.name === 'Credit Card') {
      conversionType = ConversionTypes.PreJoinCreditCardSubmit;
    }

    props.signalSubEvent(conversionType);
  }, [paymentOption, props, purchasing]);

  const paymentTypeForApi = useMemo(() => {
    switch (activePayment?.name) {
      case 'RocketGate':
      case 'CCBill':
      case 'Epoch':
      case 'Credit Card':
      case 'PayPal':
        return 'cc';
      case 'Bitcoin':
        return 'btc';
      case 'Boleto':
        return 'boleto';
      default:
        return '';
    }
  }, [activePayment]);

  const showItem = useCallback(
    (cartItem) => {
      if (cartItem && cartItem.redirectURL) {
        setRedirecting(true);
        window.location.replace(cartItem.redirectURL);
      } else if (cartItem && cartItem.iframeURL) {
        setIFrameUrl(cartItem.iframeURL);
      } else if (cartItem && cartItem.postURL) {
        setPostURL(cartItem.postURL);
        setPostData(cartItem.postData);
      } else {
        addToast('Unable to present form to complete purchase, please contact support.', {
          appearance: 'error',
          autoDismiss: false,
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    const handleMessage = async (e: MessageEvent) => {
      if (
        e &&
        e.data &&
        e.data.source === 'onCompletePage' &&
        e.origin === `${window.location.protocol}//${window.location.host}`
      ) {
        // NOTE: you get messages in from react dev tools... filter by source property
        if (e.source) {
          (e.source as WindowProxy).close();
        }

        if (+e.data.errcat > 0) {
          window.location.assign('/?response=declined');
          return;
        }

        if (e.data.success) {
          window.ata?.track({ name: 2 });
        }

        const storedData = getData<StoredOrderData>(cartStorageKey);
        if (!storedData || storedData.cartUUID !== e.data.cartUUID) {
          return;
        }

        // send infinity tag event here since it's a successful purchase
        sendSubmitInfoEventOnPurchase();

        // this is here to allow the above event to finish, otherwise it will be cleaned up at the end
        await delay(500);

        if (Array.isArray(storedData.cartItems)) {
          storedData.cartItems = storedData.cartItems.map((cartItem) => {
            if (cartItem.cartItemUUID === e.data.cartItemUUID || `${e.data.all}` === '1') {
              return {
                ...cartItem,
                status: e.data.success ? 1 : 2,
                ...(!e.data.success && +e.data.billerID !== 1
                  ? {
                      cascade: Biller.RocketGate,
                    }
                  : {}),
              };
            }
            return cartItem;
          });

          const nextItem = storedData.cartItems.find((cartItem) => cartItem.status === 0);

          saveData(cartStorageKey, storedData);

          if (nextItem) {
            setItemUUID(nextItem.cartItemUUID);
            showItem(nextItem);
          } else {
            const querystring = new URLSearchParams(e.data);

            // forcing it to be Credit Card since ONLY RocketGate hits this handler
            querystring.set('type', 'cc');

            window.location.assign(`${routerPaths.purchase.success}?${querystring.toString()}`);
          }
        }
      }
    };

    window.addEventListener('message', handleMessage, false);

    return function cleanup() {
      window.removeEventListener('message', handleMessage);
    };
  }, [sendSubmitInfoEventOnPurchase, showItem, pushGoogleAnalyticsEvent]);

  const fetchCascade = useCallback(async () => {
    const api = new ApiClient();

    // This is working even though the type is wrong and this has deep tendrils
    // into the rest of the app. We're not going to fix it right now.
    const products = [ensure(cascadeItem?.product)] as CreateOrderData['products'];

    const data: CreateOrderData = {
      affiliateID,
      billerID: Biller.RocketGate,
      campaign: searchParams.get('campaign') || undefined,
      cascade: true,
      crossSale: true,
      email,
      nn: nonNudeMatch,
      password,
      paymentType: paymentOption?.name,
      products,
      promoCode: promoCode || undefined,
      source: 'join-page',
      type: activeSub ? 'upgrade' : 'join',
      accountNeedsAgeVerification: emblemAgeVerificationRequired,
    };

    try {
      const { body } = await api.post(apiUrls.post.createOrder, { data });

      if (body.cartUUID) {
        saveData(cartStorageKey, { ...body, originalProduct: cascadeItem?.product });
        history.push(`${nonNudeMatch ? '/nn' : ''}/purchase`);
        setTimeout(() => {
          setIsCascading(false);
          setCascadeCountDown(0);
        }, 200);
      } else {
        addToast(body.errorText || 'Unable to create your order, please try again later', {
          appearance: 'error',
          autoDismiss: false,
        });
      }
    } catch (err) {
      addToast('Unable to create your order, please try again later', { appearance: 'error', autoDismiss: false });
    }
  }, [
    activeSub,
    addToast,
    affiliateID,
    cascadeItem,
    email,
    history,
    nonNudeMatch,
    password,
    paymentOption,
    promoCode,
    searchParams,
    setCascadeCountDown,
    setIsCascading,
    emblemAgeVerificationRequired,
  ]);

  useEffect(() => {
    if (isCascading) {
      if (cascadeCountDown > 0) {
        setTimeout(() => {
          setCascadeCountDown(cascadeCountDown - 1);
        }, 1000);
      } else {
        fetchCascade().then(() => {
          setIsCascading(false);
        });
      }
    }
  }, [isCascading, cascadeCountDown, fetchCascade]);

  useEffect(() => {
    let storedData = getData<StoredOrderData>(cartStorageKey);
    let cascading = false;
    if (
      storedData &&
      !isCascading &&
      purchaseResponse &&
      purchaseResponse === 'declined' &&
      (biller === '3' || biller === '10')
    ) {
      const redoItem = storedData.cartItems?.find((i) => i.status === 0 || i.status === 2);
      if (!cascadeItem && redoItem) {
        cascading = true;
        setCascadeItem(redoItem);
        saveData(cartStorageKey, {
          ...storedData,
          cartItems: storedData.cartItems?.map((i) => {
            if (i.status === 0) {
              return {
                ...i,
                status: 2,
                cascade: Biller.RocketGate,
              };
            }
            return i;
          }),
        });
        storedData = getData<StoredOrderData>(cartStorageKey);
      }
    }

    if (!purchaseMatch && !nonNudePurchaseMatch && !isCascading) {
      if (purchaseResponse && purchaseResponse === 'declined') {
        if (cascadeItem && storedData && storedData.billerID !== 1) {
          setIsCascading(true);
          setCascadeCountDown(5); // 5 seconds
          return;
        }
        if (!cascadeItem && !cascading) {
          let message = `Unable to complete the purchase through the selected payment processor.${
            biller ? ' Please try a different payment method.' : ''
          }`;

          if (galleryPurchase) {
            message =
              'The purchase of your gallery was declined. Please try a different payment method or update your payment details in the account center.';
          }

          addToast(message, { appearance: 'error', autoDismiss: false });
        }
      }
    }
  }, [
    addToast,
    biller,
    nonNudePurchaseMatch,
    purchaseMatch,
    purchaseResponse,
    galleryPurchase,
    isCascading,
    cascadeCountDown,
    cascadeItem,
  ]);

  const handleOrderResponse = useCallback(
    (response) => {
      const { canChargeByPrevious } = response;
      if (response.cartItems.length > 0) {
        const [cartItem] = response.cartItems;

        setItemUUID(cartItem.cartItemUUID);
        if (cartItem.product) {
          const validation = parseFloat(cartItem.product.initialPrice) === 0 && cartItem.product.recurringPeriod === 0;
          setValidationRequired(validation);
        }

        if (!canChargeByPrevious) {
          showItem(cartItem);
        }
      } else {
        addToast('Unable to create your order, please try again later', {
          appearance: 'error',
          autoDismiss: false,
        });
        history.push(`${nonNudePurchaseMatch ? routerPaths.nonNude.path : '/'}`);
      }

      if (canChargeByPrevious) setPurchasing(false);
      setCanCBP(canChargeByPrevious);
    },
    [addToast, history, nonNudePurchaseMatch, showItem],
  );

  useEffect(() => {
    if (purchaseMatch || nonNudePurchaseMatch) {
      const response = getData(cartStorageKey);

      if (response && Object.keys(response).length > 0) {
        handleOrderResponse(response);
      } else {
        history.push(`${nonNudePurchaseMatch ? routerPaths.nonNude.path : '/'}`);
      }
    } else {
      setIFrameUrl('');
      setCanCBP(false);
      setPurchasing(false);
    }
  }, [handleOrderResponse, history, nonNudePurchaseMatch, purchaseMatch]);

  const selectPlan = useCallback(
    async (priceGroupProductID, paymentOptionOverride) => {
      setSubOption(priceGroupProductID);

      const paymentType = paymentOptionOverride || activePayment;
      const payGroup = paymentPrices?.find((r) => r.billerID === paymentType?.billerID);
      const plans = payGroup?.subscriptionPlans;
      const product = plans?.find((p) => p.priceGroupProductID === priceGroupProductID);

      let affiliateCode: string | undefined;

      if (mamPartnerID) {
        affiliateCode = `mam-${mamPartnerID}`;
      }

      if (affiliateID) {
        affiliateCode = affiliateID;
      }

      if (!product || !site || !payGroup || !activePayment) return;

      pushSelectItemToGoogleAnalytics(affiliateCode, promoCode, product, site);
      if (!isUser) {
        if (!validEmail) {
          setValidEmail(false);
        }

        if (!validPassword) {
          setValidPassword(false);
        }

        if (!validEmail || !validPassword || isValidatingEmail) {
          return;
        }
      }

      setPurchasing(true);

      const api = new ApiClient();
      const data: CreateOrderData = {
        affiliateID,
        billerID: payGroup.billerID,
        campaign: searchParams.get('campaign') || undefined,
        crossSale: true,
        email,
        nn: nonNudeMatch,
        password,
        paymentType: activePayment.name,
        products: [product],
        promoCode: promoCode || undefined,
        source: 'join-page',
        type: upgrade ? 'upgrade' : 'join',
        accountNeedsAgeVerification: emblemAgeVerificationRequired,
      };

      try {
        const { body } = await api.post(apiUrls.post.createOrder, { data });

        if (body.cartUUID) {
          saveData(cartStorageKey, { ...body, originalProduct: product });

          // send infinity tag here since create order was sent
          sendSelectPlanEventOnClick();

          window.dataLayer.push({ userId: body.networkUserUUID });
          pushBeginCheckoutToGoogleAnalytics(affiliateCode, product, promoCode, BillerById[body.billerID], site);

          // send GA event about payment option
          pushGoogleAnalyticsEvent('ab_test_plan_selected', paymentOption?.name || 'Other');
        }

        if (!user.isUser) {
          window.location.assign(`${body.redirectURL}${location.search ? `&${location.search.substring(1)}` : ''}`);
        } else if (body.cartUUID && body.cartItems.length > 0) {
          history.push(`${nonNudeMatch ? '/nn' : ''}/purchase${location.search}`);
        } else {
          setPurchasing(false);
          addToast(body.errorText || 'Unable to create your order, please try again later', {
            appearance: 'error',
            autoDismiss: false,
          });
        }
      } catch (e: unknown) {
        setPurchasing(false);

        const error = e as SuperAgentError;

        if (error?.response?.status === 409) {
          toggleUserExists(true);
        } else {
          addToast('Unable to create your order, please try again later', { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [
      activePayment,
      paymentPrices,
      mamPartnerID,
      affiliateID,
      site,
      promoCode,
      isUser,
      searchParams,
      email,
      nonNudeMatch,
      password,
      upgrade,
      validEmail,
      validPassword,
      isValidatingEmail,
      user.isUser,
      sendSelectPlanEventOnClick,
      location.search,
      history,
      addToast,
      emblemAgeVerificationRequired,
      pushGoogleAnalyticsEvent,
      paymentOption?.name,
    ],
  );

  const onEmailChange = (value: string) => {
    setEmail(value);

    if (!validEmail && validEmail !== '') {
      setValidEmail('');
    }
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);

    if (!validPassword && validEmail !== '') {
      setValidPassword('');
    }
  };

  const validatePassword = () => {
    setValidPassword(isLength(password, { min: 4 }));
  };

  const chargeByPrevious = async (cartItemUUID?: string) => {
    setPurchasing(true);
    const storedData = getData<StoredOrderData>(cartStorageKey);

    const cartItem = storedData.cartItems?.find((item) => item.cartItemUUID === cartItemUUID) || ({} as CartItem);

    let body: Partial<{
      upgradeAborted: boolean;
      success: boolean;
      subscriptionID: string;
      iframeURL: string;
      redirectURL: string;
      postURL: string;
      postData: string;
    }> = {};
    try {
      const api = new ApiClient();
      ({ body } = await api.post(apiUrls.post.chargeByPrevious, {
        data: {
          cartItemUUID: cartItem?.cartItemUUID,
          billerID: storedData.billerID,
        },
      }));

      if (body.upgradeAborted) {
        addToast('Unable to upgrade subscription, please contact support', { appearance: 'error', autoDismiss: false });
        return;
      }

      if (body.success) {
        const type = paymentTypeForApi;

        const querystring = new URLSearchParams({
          billerID: `${storedData.billerID}`,
          cartItemUUID: cartItem?.cartItemUUID,
          subscriptionID: body.subscriptionID || '',
          type,
        });

        history.push(`${routerPaths.purchase.success}?${querystring.toString()}`);
        return;
      }

      if (body.iframeURL) {
        cartItem.iframeURL = body.iframeURL;
      }

      if (body.redirectURL) {
        cartItem.redirectURL = body.redirectURL;
      }

      if (body.postURL) {
        cartItem.postURL = body.postURL;
        cartItem.postData = body.postData || '';
        cartItem.redirectURL = '';
      }
    } catch (error) {
      //
    }

    if (!cartItem.iframeURL && !cartItem.redirectURL && !cartItem.postURL) {
      const message = !cartItem ? 'Missing cart data' : 'Missing form data';
      addToast(`Unable to complete purchase (${message}), please contact support`, {
        appearance: 'error',
        autoDismiss: false,
      });
      return;
    }

    setCanCBP(false);
    setPurchasing(false);
    showItem(cartItem);
  };

  const selectPaymentOption = useCallback(
    (payOption) => {
      if (purchasing) {
        return;
      }

      if (payOption.name === 'Gift Card') {
        const payGroup = paymentPrices?.find((r) => r.paymentType === 'Gift Card');
        const [plan] = payGroup?.subscriptionPlans || [];
        const { priceGroupProductID } = plan;
        selectPlan(priceGroupProductID, { name: payGroup?.paymentType, billerID: payGroup?.billerID });
      } else {
        setPaymentOption(payOption);
      }
    },
    [purchasing, paymentPrices, selectPlan],
  );

  const selectAltPayment = (altPayment?: AltPayment) => {
    setPaymentOption(undefined);
    setSelectedAltPayment(altPayment);
  };

  const selectPrimaryPayment = (billerID?: number) => {
    const payment = paymentPrices?.find((r) => r.billerID === billerID);
    selectPaymentOption({ name: payment?.paymentType, billerID: payment?.billerID });
    setShowAlternativePaymentPanel(false);
    setSelectedAltPayment(undefined);
  };

  // Get the active subscriptions from the selected biller id, or show the 1st
  // set of subscriptions from the result.
  const subscriptions = useMemo(() => {
    const newSubs =
      paymentPrices?.find((r) => r.billerID === activePayment?.billerID)?.subscriptionPlans ||
      paymentPrices?.[0].subscriptionPlans ||
      undefined;
    // Reset selected sub in case new list has no matching product
    if (subOption && !newSubs?.some((sub) => sub.priceGroupProductID === subOption)) {
      setSubOption(undefined);
    }
    return newSubs;
  }, [activePayment?.billerID, paymentPrices, subOption]);

  return (
    <Container>
      {redirecting ? (
        <RedirectSection />
      ) : (
        <>
          <Header useAffiliateHeader />
          <div className="container">
            {isCascading && cascadeItem ? (
              <CascadeMessage cascadeCountDown={cascadeCountDown} />
            ) : (
              <div className="purchase-data">
                {iframeUrl && !canCBP && (
                  <PurchaseFrame iframeUrl={iframeUrl} validationRequired={validationRequired} />
                )}
                {postURL && postData && <UpgradeFrame postURL={postURL} postData={postData} title="Complete Upgrade" />}
                {canCBP && !postURL && (
                  <ChargeByPrevious loading={purchasing} onClick={() => chargeByPrevious(itemUUID)} />
                )}
                {!iframeUrl && !canCBP && !postURL && (
                  <JoinLayout>
                    <EmailForm
                      customError={customError}
                      email={email}
                      doesUserExist={doesUserExist}
                      isUser={isUser}
                      password={password}
                      setPassword={onPasswordChange}
                      setEmail={onEmailChange}
                      validEmail={validEmail}
                      validPassword={validPassword}
                      emailOnBlur={emailOnBlur}
                      validatePassword={validatePassword}
                      validatingEmail={isValidatingEmail}
                    />
                    <PaymentChoice
                      className="payment-method"
                      cdnUrl={cdnUrl}
                      selectPrimaryPayment={selectPrimaryPayment}
                      paymentOption={paymentOption}
                      previousBillerID={previousBillerID}
                      result={paymentPrices}
                      setPaymentOption={setPaymentOption}
                      selectedAltPayment={selectedAltPayment}
                      setShowAlternativePaymentPanel={setShowAlternativePaymentPanel}
                      showAlternativePaymentPanel={showAlternativePaymentPanel}
                    />
                    {showAlternativePaymentPanel && (
                      <AlternativePayments
                        altPayments={activeAlternativePayments}
                        cdnUrl={cdnUrl}
                        country={countryName}
                        selectedAltPayment={selectedAltPayment}
                        selectAltPayment={selectAltPayment}
                        setShowAlternativePaymentPanel={setShowAlternativePaymentPanel}
                      />
                    )}
                    <SubscriptionChoice
                      paymentOption={activePayment}
                      subOption={subOption}
                      loading={purchasing}
                      onClick={purchasing ? noop : selectPlan}
                      subscriptions={subscriptions}
                      signalPayByCheck={() => props.signalSubEvent(ConversionTypes.PreJoinAlternativePaymentSubmit)}
                    />
                  </JoinLayout>
                )}
              </div>
            )}
            <BottomPage>
              <StyledTeasers />
              <StyledFeatures featuresHeader={site.featuresHeader} />
              <StyledTestimonials />
            </BottomPage>
            <SecuritySection />
            <ChatWidget jira-id="9dab1c19-00bf-3be7-b9b6-14a91ae44549" service-desk-id="3" />
            <MainFooter />
          </div>
          <LoginReminderModal show={showLoginModal} onHide={() => setShowLoginModal(false)} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;

  & .purchase-data {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;

const BottomPage = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 8px;
`;

const StyledTeasers = styled(Teasers)`
  grid-column: 1 / span 2;
`;

const bottomPageBox = css`
  border: 1px solid lightgray;
  height: 100%;
  grid-column: span 2;
  overflow: hidden;
  padding: 36px;

  @media ${TABLET_LANDSCAPE} {
    grid-column: span 1;
  }
`;

const StyledFeatures = styled(FeaturesList)`
  ${bottomPageBox}
`;

const StyledTestimonials = styled(Testimonials)`
  ${bottomPageBox}
`;

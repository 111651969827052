export const cartStorageKey = 'billing.cart';
export const upgradeCartStorageKey = 'billing.upgrade.cart';

export const BILLING_TEST_ORDER_COOKIE = 'billing_test_order';
export const SITE_TEST_ORDER_COOKIE = 'site_test_order';
export const accountCenterUrl = 'https://account-new.metartnetwork.com/';

export const ORDER_STATUS = {
  UNPROCESSED: 0,
  SUCCESS: 1,
  ERROR: 2,
  REDIRECT: 3,
};

export const CAM_TRACKING_CODES = {
  UPGRADES: '1-203',
  MEMBERS_PORTAL: '1-204',
};

export const CAMPAIGNS = {
  FLOATY: 'gateway-replacement-floater',
  MEMBERSPORTAL_GATEWAY: 'ad-zone-gateway-desktop',
} as const;

export const apiUrls = {
  get: {
    account: '/api/account',
    ageVerificationCallback: '/api/age-verification-callback',
    appData: '/api/app-data',
    cams: '/api/cams',
    invoice: '/api/invoice',
    prices: '/api/prices',
    upgrades: '/api/upgrades',
    upsales: '/api/upsales',
  },
  post: {
    chargeByPrevious: '/api/charge-by-previous',
    createOrder: '/api/create-order',
    createSubscriptions: '/api/create-subscriptions',
    analytics: '/api/analytics',
    createOneClickURL: '/api/create-oneclick',
  },
};

export const routerPaths = {
  purchase: {
    gallery: '/purchase/gallery',
    site: '/purchase',
    success: '/purchase/success',
    upgrade: '/purchase/upgrade',
    upsales: '/purchase/subscriptions',
  },
  gateway: '/gateway',
  nonNude: {
    purchase: {
      site: '/nn/purchase',
    },
    path: '/nn',
  },
  upgrades: {
    sites: '/upgrades',
    checkout: '/upgrades/checkout',
  },
  membersPortal: {
    page: '/members-portal',
    checkout: '/members-portal/checkout',
  },
  ageVerification: {
    callback: '/age-verification-callback',
  },
};

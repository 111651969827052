import React from 'react';
import { Helmet } from 'react-helmet';
import { useAppData } from '../helpers';

export const HTMLHead = () => {
  const { site, cdnUrl } = useAppData();

  return (
    <Helmet>
      <title>{site ? `Join ${site.name} Now!` : 'Join Now!'}</title>
      {site && <link rel="icon" href={`${cdnUrl}/favicon/${site.abbreviation}.ico`} />}
      <script>
        {`(function (t, a) { window.ata = a, a.init = function (t, r) { a._token = t, r && (a._initParams = r); }, a._calls = []; for (let r = ['track'], c = 0; c < r.length; c += 1) { const i = r[c]; a[i] = (function (t) { return function () { a._calls.push([t, Array.prototype.slice.call(arguments, 0)]); }; }(i)); } const script = t.createElement('script'); script.type = 'text/javascript'; script.async = !0; script.src = 'https://ata-cdn.metart.network/bundle.js'; const scriptTag = t.getElementsByTagName('script')[0]; scriptTag.parentNode.insertBefore(script, scriptTag); }(document, window.ata || []))`}
      </script>
      <script>{`ata.init('${site.networkUUID}', { skipRawHit: true })`}</script>
    </Helmet>
  );
};

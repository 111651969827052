import React from 'react';
import styled from 'styled-components/macro';

type Props = {
  onClick: () => void;
};

export const UpgradeHeaderStrip = (props: Props) => {
  return (
    <Strip>
      <Text onClick={props.onClick}>
        <b>SPECIAL UPGRADE OFFER! -</b>&nbsp;Select any&nbsp;<b>Lifetime Membership</b>&nbsp;and receive&nbsp;
        <b>1 FREE YEAR</b>&nbsp;of any second site of your choosing.
      </Text>
    </Strip>
  );
};

const Strip = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #ffcc00;
  flex-wrap: wrap;
  padding: 5px 10px;
  text-align: center;
`;

const Text = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

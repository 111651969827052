import React from 'react';
import { AltPayment, AltPayments } from '@tovia/man-protos/dist/types/billing.types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { entries } from 'src/shared/helpers/entries';

type Props = {
  items?: AltPayments;
  onItemClick: (altPayment: AltPayment) => void;
};

export const OtherPaymentsDropdown = (props: Props) => {
  const { items, onItemClick } = props;

  return (
    <DropdownContainer>
      <ul>
        {entries(items).map(([paymentGroup, payments]) => {
          const disabled = paymentGroup === 'international';
          return (
            <React.Fragment key={paymentGroup}>
              {payments.map((payment, id) => (
                <DropdownListItem disabled={disabled} key={id} onClick={() => onItemClick(payment)}>
                  {payment.name}
                </DropdownListItem>
              ))}
            </React.Fragment>
          );
        })}
      </ul>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  width: 100%;
  z-index: 1;
  position: absolute;
  background-color: #fff;
  top: calc(100% + 5px);
  left: 0;
  padding: 20px 0;
  max-height: 170px;
  overflow-y: scroll;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);

  ul {
    list-style-type: none;
    padding: 0 25px 0 15px;
    margin: 0;
  }
`;

const DropdownListItem = styled.li<{ disabled?: boolean }>`
  padding: 7px 5px;
  border-top: 1px solid #e6e6e6;

  :hover {
    background-color: #f0f0f0;
  }

  :last-child {
    border-bottom: 1px solid #e6e6e6;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: lightgray; //TODO
    `}
`;

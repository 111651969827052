import { AltPayment } from '@tovia/man-protos/dist/types/billing.types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApiClient } from 'src/client/helpers/ApiClient';
import { apiUrls, routerPaths } from 'src/client/helpers/constants';
import { useAppData } from 'src/client/helpers/useAppData';
import { Biller } from 'src/shared/constants/billers';
import { GetPricesResponse } from 'src/shared/types/Prices';
import { useQueryParams } from '../PreJoinPage/PreJoinPage';

export const usePreJoinPagePrices = () => {
  const { user } = useAppData();
  const purchaseMatch = !!useRouteMatch({ exact: true, path: routerPaths.purchase.site });
  const nonNudePurchaseMatch = !!useRouteMatch({ strict: true, path: routerPaths.nonNude.purchase.site });
  const history = useHistory();
  const { addToast } = useToasts();
  const { affiliateID, promoCode, giftCard, purchaseResponse, biller, ipAddress } = useQueryParams();
  const [data, setData] = useState<GetPricesResponse>();

  const params = useMemo(
    () => ({
      affiliateID,
      exclude: purchaseResponse === 'declined' && biller ? biller : undefined,
      giftCard,
      ipAddress,
      promoCode,
    }),
    [affiliateID, biller, giftCard, ipAddress, promoCode, purchaseResponse],
  );

  useEffect(() => {
    if (purchaseMatch || nonNudePurchaseMatch) {
      return;
    }

    (async () => {
      const api = new ApiClient();
      try {
        const { body } = (await api.get(apiUrls.get.prices, { params })) as { body: GetPricesResponse };
        const { paymentPrices, previousBillerID, activeSub } = body;

        if (paymentPrices.length === 0 && user?.isUser && ![4, 5].includes(previousBillerID)) {
          history.push(routerPaths.purchase.upsales);
          return;
        }

        if (paymentPrices.length === 0) {
          throw new Error('PRICES_ERROR');
        }

        const allPayments = Object.values(body.altPayments ?? {}).flatMap((a) => [...a] as AltPayment[]);
        const epoch = allPayments.find((a) => a.billerID === Biller.Epoch);

        const activeBiller = allPayments.find(
          (a) => a.billerID === activeSub?.billerID || a.billerID === previousBillerID,
        );

        const paymentOption =
          previousBillerID === Biller.Epoch && !!epoch
            ? epoch
            : activeBiller || { name: paymentPrices[0].paymentType, billerID: paymentPrices[0].billerID };

        const subscription = body.paymentPrices.find((r) => r.billerID === paymentOption.billerID)
          ?.subscriptionPlans[0];

        if (!subscription) {
          history.push(routerPaths.purchase.upsales);
        }

        // setPaymentOption(paymentOption);
        setData(body);
      } catch (error) {
        addToast('Unable to get subscription prices', { appearance: 'error', autoDismiss: false });
      }
    })();

    window.ata?.track({ name: 1 });
  }, [addToast, history, params, purchaseMatch, nonNudePurchaseMatch, user]);

  return data;
};

import { Invoice } from '@tovia/man-protos/dist/types/billing.types';
import { resolveProduct } from 'src/client/helpers/utils';
import { InvoiceGallery } from 'src/client/pages/SuccessPage/SuccessPage';
import { BillerById } from 'src/shared/constants/billers';
import { NetworkSite, Promotions } from 'src/shared/types';

type GaPurchase = {
  affiliateCode?: string;
  cartItemUUID: string | null;
  galleryItem?: InvoiceGallery;
  galleryUUID: string | null;
  invoices: Invoice[];
  networkSites: NetworkSite[];
  promotions: Promotions;
  quality: string | null;
};

export const sendPurchaseGaData = ({
  affiliateCode,
  cartItemUUID,
  galleryUUID,
  invoices,
  galleryItem,
  networkSites,
  promotions,
  quality,
}: GaPurchase) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: cartItemUUID,
      affiliate_id: affiliateCode,
      source: galleryUUID || invoices[0].isPPS ? 'PPS Dialog' : 'Join Page',
      value: invoices.reduce((acc: number, val: Invoice) => acc + parseFloat(val.amount), 0),
      tax: invoices.reduce((acc: number, val: Invoice) => acc + parseFloat(val.vatTax), 0),
      currency: invoices[0].currency,
      coupon: promotions.promoCode,
      payment_method: BillerById[invoices[0].billerID],
      items: invoices.map((item: Invoice) => {
        const product = resolveProduct(item, networkSites);

        let { itemName, itemBrand } = product;
        let itemCategory = 'subscription';
        const extraFields: Record<string, string> = {};

        if (galleryUUID || item.isPPS) {
          itemCategory = 'pps';
          extraFields.item_category2 = quality
            ? `${quality.charAt(0).toUpperCase()}${quality.slice(1)}`
            : 'No Quality Provided';
        }

        if (galleryItem) {
          itemName = galleryItem.name;
          itemBrand = galleryItem.isStaffSelection ? 'Staff Selection' : itemBrand;
          extraFields.item_list_name = galleryItem.type === 'movie' ? 'Films' : 'Galleries';
          extraFields.item_list_id = galleryItem.UUID;
        }

        return {
          item_name: itemName,
          item_id: item.productUUID,
          item_brand: itemBrand,
          item_category: itemCategory,
          ...extraFields,
          quantity: 1,
          price: parseFloat(item.amount),
        };
      }),
    },
  });
  window.dataLayer.push({
    event: 'purchase_ua',
    ecommerce: {
      currency: invoices[0].currency,
      purchase: {
        actionField: {
          id: cartItemUUID,
          affiliate_id: affiliateCode,
          source: galleryUUID || invoices[0].isPPS ? 'PPS Dialog' : 'Join Page',
          revenue: invoices.reduce((acc: number, val: Invoice) => acc + parseFloat(val.amount), 0),
          tax: invoices.reduce((acc: number, val: Invoice) => acc + parseFloat(val.vatTax), 0),
          coupon: promotions.promoCode,
          option: BillerById[invoices[0].billerID],
        },
        products: invoices.map((item: Invoice) => {
          const product = resolveProduct(item, networkSites);

          let { itemName, itemBrand } = product;
          let itemCategory = 'subscription';
          const extraFields: Record<string, string> = {};

          if (galleryUUID || item.isPPS) {
            itemCategory = 'pps';
            extraFields.category2 = quality
              ? `${quality.charAt(0).toUpperCase()}${quality.slice(1)}`
              : 'No Quality Provided';
          }

          if (galleryItem) {
            itemName = galleryItem.name;
            itemBrand = galleryItem.isStaffSelection ? 'Staff Selection' : itemBrand;
            extraFields.variant = galleryItem.type === 'movie' ? 'Films' : 'Galleries';
            extraFields.id = galleryItem.UUID;
          }

          return {
            name: itemName,
            id: item.productUUID,
            brand: itemBrand,
            category: itemCategory,
            ...extraFields,
            quantity: 1,
            price: parseFloat(item.amount),
          };
        }),
      },
    },
  });
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ABTestNames } from 'src/shared/types/ABTests';
import { ABTestDefinitions, getABVariantClientStorageKey } from 'src/shared/constants/abTests';
import { ConversionType, ConversionTypes, MeasurementTypes } from 'src/shared/types/userMeasurement';
import { PreJoinPage } from '../pages';
import { useAnalyticsSendABTestMeasurement } from '../components/Analytics/useAnalyticsSendABTestMeasurement';
import { useAnalyticsABMeasurement } from '../components/Analytics/useAnalyticsABMeasurement';
import PreJoinPageRedesign from '../pages/PreJoinPageRedesign/PreJoinPageRedesign';
import { useAppData, useURLSearchParams } from './index';

type Props = {
  abTestName: ABTestNames;
  variants: {
    1: JSX.Element;
    [k: number]: JSX.Element;
  };
};

const getABVariantFromSearchParam = (params: URLSearchParams, abTestName: string): number | undefined => {
  const paramName = `${abTestName.toLowerCase()}variant`;
  for (const [k, v] of params.entries()) {
    if (k.toLowerCase() === paramName) {
      return parseInt(v, 10);
    }
  }

  return undefined;
};

export const ABSwitchComponent = (props: Props) => {
  const { abTestName, variants } = props;
  const { abTests } = useAppData();

  const searchParams = useURLSearchParams();
  const abSearchParam = getABVariantFromSearchParam(searchParams, abTestName);

  const abStorageKey = getABVariantClientStorageKey(abTestName);
  const [cookies, setCookie] = useCookies([abStorageKey]);
  const aBTestCookieVariant = cookies[abStorageKey] as number | undefined;

  const variant = useMemo(() => abSearchParam || aBTestCookieVariant, [aBTestCookieVariant, abSearchParam]);
  const abTestId = useMemo(() => ABTestDefinitions[abTestName]?.id, [abTestName]);

  useAnalyticsSendABTestMeasurement({
    abTestId: abTestId,
    type: MeasurementTypes.View,
  });

  useEffect(() => {
    if (!aBTestCookieVariant && abTests?.[abTestName]) {
      setCookie(abStorageKey, abTests?.[abTestName]?.variant, { path: '/' });
    }
  }, [aBTestCookieVariant, abStorageKey, abTestName, abTests, setCookie]);

  if (!variant || !variants[variant]) {
    return variants[1];
  }

  return variants[variant];
};

export type PreJoinPageABProps = {
  signalFormEvent: (isValidForm: boolean) => void;
  signalSubEvent: (conversionType: ConversionType) => void;
};

export const PreJoinPageAB = () => {
  const { user } = useAppData();
  const { sendABTestMeasurement } = useAnalyticsABMeasurement({ abTestId: ABTestDefinitions.PreJoinPage.id });
  const [formEventFired, setFormEventFired] = useState<boolean>(false);
  const isValidUserType = user.userType ? ABTestDefinitions.PreJoinPage.userTypes.includes(user.userType) : false;

  const signalFormEvent = useCallback(
    (isValidForm: boolean) => {
      if (!isValidUserType || formEventFired || !isValidForm) {
        return;
      }

      sendABTestMeasurement({
        abTestId: ABTestDefinitions.PreJoinPage.id,
        type: MeasurementTypes.Conversion,
        conversionType: ConversionTypes.PreJoinFilledOutInputIsValid,
      });
      setFormEventFired(true);
    },
    [formEventFired, isValidUserType, sendABTestMeasurement],
  );

  const signalSubEvent = useCallback(
    (conversionType: ConversionType) => {
      if (!isValidUserType || !conversionType) {
        return;
      }

      sendABTestMeasurement({
        abTestId: ABTestDefinitions.PreJoinPage.id,
        type: MeasurementTypes.Conversion,
        conversionType,
      });
    },
    [isValidUserType, sendABTestMeasurement],
  );

  return (
    <ABSwitchComponent
      abTestName={'PreJoinPage'}
      variants={{
        [-1]: <PreJoinPageRedesign signalFormEvent={signalFormEvent} signalSubEvent={signalSubEvent} />,
        1: <PreJoinPage signalFormEvent={signalFormEvent} signalSubEvent={signalSubEvent} />,
        2: <PreJoinPage signalFormEvent={signalFormEvent} signalSubEvent={signalSubEvent} />,
      }}
    />
  );
};

import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useSuccessPageQueryParams } from 'src/client/pages/SuccessPage/Hooks/useSuccessPageQueryParams';
import { useAnalyticsABMeasurement } from 'src/client/components/Analytics/useAnalyticsABMeasurement';
import { useAnalyticsSignals } from 'src/client/components/Analytics/useAnalyticsSignals';
import { useAppData } from 'src/client/helpers';
import {
  BILLING_TEST_ORDER_COOKIE,
  SITE_TEST_ORDER_COOKIE,
  accountCenterUrl,
  routerPaths,
} from 'src/client/helpers/constants';
import { getConversionTypeByPurchase } from 'src/client/helpers/utils';
import { ABTestDefinitions } from 'src/shared/constants/abTests';
import { MeasurementTypes } from 'src/shared/types/userMeasurement';
import { Biller } from 'src/shared/constants/billers';
import { InvoiceGallery } from 'src/client/pages/SuccessPage/SuccessPage';

type UseHandleSuccessfulPaymentArgs = {
  success: boolean;
  allIframesLoaded?: boolean;
  gallery?: InvoiceGallery;
  billerId?: number;
};

export const useHandleSuccessfulPayment = (args: UseHandleSuccessfulPaymentArgs): void => {
  const { signalAnalyticsNewSubscription } = useAnalyticsSignals();
  const { sendABTestMeasurement } = useAnalyticsABMeasurement({
    abTestId: ABTestDefinitions.PreJoinPage.id,
  });
  const { cartItemUUID, extSubscriptionID, galleryUUID, paymentType, purchaseType, isMultiPurchase } =
    useSuccessPageQueryParams();
  const { success, gallery, allIframesLoaded, billerId } = args;
  const { site } = useAppData();
  const history = useHistory();
  const [, , removeCookie] = useCookies([BILLING_TEST_ORDER_COOKIE, SITE_TEST_ORDER_COOKIE]);

  useEffect(() => {
    const billerNeedsIframe = billerId === Biller.Epoch || billerId === Biller.CCBill;

    const handlePayment = async () => {
      if (billerNeedsIframe && !allIframesLoaded) {
        return;
      }

      if (success) {
        if (purchaseType !== 'pps' && !galleryUUID) {
          const salesData =
            cartItemUUID || extSubscriptionID
              ? {
                  cartItemUUID: cartItemUUID || undefined,
                  extSubscriptionID: extSubscriptionID || undefined,
                }
              : undefined;

          await signalAnalyticsNewSubscription(salesData);

          const conversionType = getConversionTypeByPurchase({ purchaseType, paymentType });

          await sendABTestMeasurement({
            abTestId: ABTestDefinitions.PreJoinPage.id,
            type: MeasurementTypes.Conversion,
            conversionType,
          });
        }

        removeCookie(BILLING_TEST_ORDER_COOKIE, {
          domain: `.${site.domain}`,
          path: '/',
        });
        removeCookie(SITE_TEST_ORDER_COOKIE, {
          domain: `.${site.domain}`,
          path: '/',
        });

        const url = routerPaths.purchase.upsales;

        if (billerNeedsIframe && allIframesLoaded) {
          if (purchaseType === 'pps' || gallery?.path) {
            const galleryRedirect = !!gallery?.path;

            if (galleryRedirect) {
              window.location.assign(`https://${site.domain}${gallery?.path}`);
              return;
            }
          }

          if (purchaseType !== 'cc') {
            window.location.assign(`https://${site.domain}`);
            return;
          }

          if (isMultiPurchase === 'true') {
            window.location.assign(accountCenterUrl);
            return;
          }

          history.push(url);
        } else if (!billerNeedsIframe) {
          setTimeout(() => {
            if (purchaseType === 'pps' || gallery?.path) {
              const galleryRedirect = !!gallery?.path;

              if (galleryRedirect) {
                window.location.assign(`https://${site.domain}${gallery?.path}`);
                return;
              }
            }

            if (purchaseType !== 'cc') {
              window.location.assign(`https://${site.domain}`);
              return;
            }

            if (isMultiPurchase === 'true') {
              window.location.assign(accountCenterUrl);
              return;
            }

            history.push(url);
          }, 5000);
        }
      }
    };

    handlePayment();
  }, [
    billerId,
    cartItemUUID,
    extSubscriptionID,
    gallery,
    galleryUUID,
    history,
    allIframesLoaded,
    isMultiPurchase,
    paymentType,
    purchaseType,
    removeCookie,
    sendABTestMeasurement,
    signalAnalyticsNewSubscription,
    site.domain,
    success,
  ]);
};
